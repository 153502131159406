import * as jQuery from "jquery";

(function ($) {
    $(() => {
        const registration = $(".registration-container");
        // TODO: simple controller/action trigger to avoid this.
        if (registration.length === 0) {
            return;
        }

        registration.find(".registration-form").each(function () {
            const form = $(this);
            form.validate();
            new YNAB.FormDirtyTrackingAllFieldsPresent(form);
        });

        // Handle redisplaying the form with the ajax response.  Whether there's an
        // error or not is handled by showing the errors in the form or a success
        // message.
        registration.on("ajax:success", ".registration-form", (event) => {
            const xhr = event.detail[2];
            const form = event.target;
            $(form).replaceWith(xhr.responseText);

            const newForm = $(`#${form.id}`);
            newForm.find(".error:first").prev("input").focus();

            newForm.validate();
            new YNAB.FormDirtyTrackingAllFieldsPresent(newForm);

            // Updates the existing session token, in case we receive back one with the response.
            // Required for the password change flow, because we rely on the session token
            // from the page to logout the user, and changing the password resets that token.
            YNAB.setSessionToken(newForm.find("meta[name=session-token]").remove().attr("content"));
        });
    });
})(jQuery);
