declare global {
    interface Window {
        google: any;
        /**
         * Google's onload callback
         */
        onGoogleLibraryLoad(): void;
        /**
         * Workaround to share this module with JS in app/assets/javascripts
         */
        GoogleIdentity: typeof ExportedGoogleIdentity;
    }
}

interface Config {
    /**
     * This field is your application's client ID,
     * which is found and created in the Google Developers Console.
     */
    googleClientId: string;
    /**
     * This attribute sets the DOM ID of the container element.
     * If it's not set, the One Tap prompt is displayed in the top-right corner of the window.
     */
    promptParentId?: string;
}

interface RenderButtonOptions {
    width?: string;
}

type IdentityServicesInitializedCallback = (error?: string) => any;

type UserIdentifiedCallback = (token: string) => any;

const ExportedGoogleIdentity = {
    /**
     * Custom callback when Google's library has been initialized
     * @deprecated Remove after migrating app/assets/javascripts/ynab.js to app/javascript/
     */
    onIdentityServicesInitialized: onIdentityServicesInitialized,
    /**
     * Custom callback when Google's user has been identified
     * @deprecated Remove after migrating app/assets/javascripts/ynab.js to app/javascript/
     */
    onUserIdentified: onUserIdentified,
    /**
     * Renders a Google authentication button
     * @deprecated Remove after migrating app/assets/javascripts/ynab.js to app/javascript/
     */
    renderButton: renderButton,
    /**
     * Triggers request for a one tap prompt. Does not guarantee it will display.
     */
    oneTapPrompt: oneTapPrompt,
};

/**
 * Exposes some of the exported functions in this module to the global scope
 * so they can be used by JS code outside of this build environment
 */
window.GoogleIdentity = ExportedGoogleIdentity;

// Shared state between module functions
let isInitialized: boolean | string = false;
let initErrorMessage: string | undefined;
let userTokenCache: string | undefined;

// Keep a reference to all the callbacks
const identityServicesInitializedCallbacks: IdentityServicesInitializedCallback[] = [];
const userIdentifiedCallbacks: UserIdentifiedCallback[] = [];

function notifyIdentityServicesInitializedCallbackQueue(errorMessage?: string) {
    identityServicesInitializedCallbacks.forEach((cb) => cb(errorMessage));
}

function notifyUserIdentifiedListeners(token: string) {
    userTokenCache = token;
    userIdentifiedCallbacks.forEach((cb) => cb(token));
}

export function onIdentityServicesInitialized(initializedCallback: IdentityServicesInitializedCallback): void {
    if (isInitialized) {
        initializedCallback(initErrorMessage);
    } else {
        identityServicesInitializedCallbacks.push(initializedCallback);
    }
}

export function onUserIdentified(userIdentifiedCallback: UserIdentifiedCallback): void {
    if (userTokenCache) {
        userIdentifiedCallback(userTokenCache);
    } else {
        userIdentifiedCallbacks.push(userIdentifiedCallback);
    }
}

/**
 * Loads Google Identity service web client.
 * Can be called multiple times, however the script loading and the timeout will only be initialized once.
 */
export function loadIdentityService(config: Config) {
    if (window.onGoogleLibraryLoad) {
        return;
    }

    let timeoutId: number | undefined = setTimeout(() => {
        timeoutId = undefined;
        if (!isInitialized) {
            initErrorMessage = "Timed out";
            notifyIdentityServicesInitializedCallbackQueue(initErrorMessage);
        }
    }, 10 * 1000);

    window.onGoogleLibraryLoad = () => {
        if (!timeoutId) {
            // already replied with a timeout; abort
            return;
        }

        clearTimeout(timeoutId);
        timeoutId = undefined;

        try {
            // Initialize Google identity services
            // https://developers.google.com/identity/gsi/web/reference/js-reference#IdConfiguration
            isInitialized = true;
            window.google.accounts.id.initialize({
                ux_mode: "popup",
                client_id: config.googleClientId,
                prompt_parent_id: config.promptParentId,
                callback({ credential: token }) {
                    notifyUserIdentifiedListeners(token);
                },
            });

            notifyIdentityServicesInitializedCallbackQueue();
        } catch (error) {
            initErrorMessage = error.message;
            notifyIdentityServicesInitializedCallbackQueue(initErrorMessage);
        }
    };

    // Load Google identity services
    // prettier-ignore
    // @ts-ignore
    // eslint-disable-next-line
    (function(d, s){var fjs=d.getElementsByTagName(s)[0],js=d.createElement(s);js.async=1;js.defer=1;js.src="https://accounts.google.com/gsi/client";fjs.parentNode.insertBefore(js, fjs);})(document, 'script');
}

export function renderButton(buttonContainer: HTMLElement, options: RenderButtonOptions) {
    onIdentityServicesInitialized(() => {
        window.google.accounts.id.renderButton(buttonContainer, {
            type: "standard",
            theme: "outline",
            size: "large",
            text: "continue_with",
            shape: "rectangular",
            logo_alignment: "center",
            width: options.width,
        });
    });
}

export function oneTapPrompt() {
    onIdentityServicesInitialized(() => window.google.accounts.id.prompt());
}
