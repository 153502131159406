import {
    hideFormErrors,
    handleCommonAuthErrors,
    addErrorToField,
    genericServerErrorMessage,
} from "ynab_api/lib/authentication_shared";
import OTPFormController from "./otp_form_controller";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    declare resetFormTarget: HTMLFormElement;
    declare resetSuccessContainerTarget: HTMLElement;
    declare resetAndDisabledContainerTarget: HTMLElement;
    declare nonOTPSuccessContainerTarget: HTMLElement;
    declare resetPasswordContainerTarget: HTMLElement;
    declare resetSubmitButtonTarget: HTMLElement;

    static targets: string[] = [
        "resetForm",
        "resetPasswordContainer",
        "resetSuccessContainer",
        "resetAndDisabledContainer",
        "nonOTPSuccessContainer",
        "resetSubmitButton",
    ];

    declare otpFormOutlet: OTPFormController;
    declare hasOtpFormOutlet: boolean;
    static outlets: string[] = ["otp-form"];

    public resetFormTargetConnected(form: HTMLFormElement): void {
        const resetForm = $(form) as JQuery<HTMLFormElement>;
        resetForm.validate();
    }

    public handleResetSuccess(_event: CustomEvent): void {
        // hide reset forms
        if (this.hasOtpFormOutlet) {
            this.otpFormOutlet.hide();
        }

        this.resetPasswordContainerTarget.hidden = true;

        // show success screens
        this.resetSuccessContainerTarget.hidden = false;
        if (this.otpFormOutlet.usingBackupCode) {
            this.resetAndDisabledContainerTarget.hidden = false;
        } else {
            this.nonOTPSuccessContainerTarget.hidden = false;
        }
    }

    public handleResetError(event: CustomEvent): void {
        const formTarget = $(this.resetFormTarget) as JQuery<HTMLFormElement>;
        hideFormErrors(formTarget);

        if (
            this.hasOtpFormOutlet &&
            this.otpFormOutlet.visible &&
            this.otpFormOutlet.handleOTPError(event, formTarget)
        ) {
            return;
        }

        const [response, _status, xhr] = event.detail;

        const error: { id: string; message: string } = response?.error;
        if (xhr.status === 401 || xhr.status === 422) {
            if (error) {
                switch (error.id) {
                    case "user_otp_required":
                        this.showOTP();
                        break;
                    default:
                        addErrorToField(formTarget, ".js-form-password", error.message);
                        break;
                }
            } else {
                addErrorToField(formTarget, ".js-form-password", genericServerErrorMessage);
            }
        } else {
            return handleCommonAuthErrors(formTarget, xhr);
        }
    }

    private showOTP(): void {
        this.resetSubmitButtonTarget.hidden = true;
        this.otpFormOutlet.show();
    }
}
