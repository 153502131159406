// This controller should always be used in conjunction with some other
// stimulus controller. It is not meant to be used on its own.
// See https://github.com/ynab/evergreen/pull/20187 for more details.
import { Controller } from "@hotwired/stimulus";
import { addErrorToField } from "ynab_api/lib/authentication_shared";
export default class extends Controller {
    declare usingBackupCodeValue: string;
    declare otpContainerTarget: HTMLElement;
    declare showBackupLinkTarget: HTMLElement;
    declare troubleshootLinkTarget: HTMLElement;
    declare labelTarget: HTMLElement;
    declare inputTarget: HTMLInputElement;
    static targets: string[] = ["otpContainer", "label", "input", "showBackupLink", "troubleshootLink"];

    static values = {
        usingBackupCode: String,
    };

    public onShowBackupHook: () => void;

    connect() {
        // this variable is set to true when the user was
        // on the backup code page and the code was incorrect
        if (this.backupCodeOnStart) {
            this.showBackup();
        }
    }

    get backupCodeOnStart(): boolean {
        return this.usingBackupCodeValue === "true";
    }

    get usingBackupCode(): boolean {
        return this.showBackupLinkTarget.hidden;
    }

    public show(): void {
        this.otpContainerTarget.hidden = false;
        this.inputTarget.required = true;
        this.inputTarget.focus();
    }

    public hide(): void {
        this.otpContainerTarget.hidden = true;
    }

    get visible(): boolean {
        return this.otpContainerTarget.hidden === false;
    }

    public handleOTPError(event: CustomEvent, form: JQuery<HTMLFormElement>): boolean {
        const [response, _error, xhr] = event.detail;

        if (xhr.status === 401 || xhr.status === 422) {
            const error: { id: string; message: string } = response?.error;

            if (error?.id === "user_otp_invalid") {
                const errorMessage = this.usingBackupCode ? "The backup code is incorrect." : error.message;
                addErrorToField(form, ".js-form-otp", errorMessage);
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    public showBackup(): void {
        this.inputTarget.value = "";

        this.showBackupLinkTarget.hidden = true;
        this.troubleshootLinkTarget.hidden = false;

        this.labelTarget.textContent = "Enter your emergency backup code.";

        const backupSize = 14;
        Object.assign(this.inputTarget, {
            placeholder: "xxxx-xxxx-xxxx",
            size: backupSize,
            maxLength: backupSize,
        });

        this.addFromBackupCodeHiddenInput();

        // this happens later in the function in case the caller wants to reference any of the above
        if (this.onShowBackupHook) {
            this.onShowBackupHook();
        }

        this.inputTarget.focus();
    }

    // for now, we only use this on the otp destroy page, since it's a remote:false request
    // and we want to communicate from the server back to the client that the form was submitted
    // from the backup code form
    private addFromBackupCodeHiddenInput(): void {
        const hiddenInput = document.createElement("input");
        hiddenInput.type = "hidden";
        hiddenInput.name = "user[submitted_from_backup_code_form]";
        hiddenInput.id = "user_submitted_from_backup_code_form";
        hiddenInput.value = "true";
        hiddenInput.autocomplete = "off";
        this.otpContainerTarget.prepend(hiddenInput);
    }
}
