export function loadClientConstants<T>(elementId = "ynab_client_constants"): T {
    const clientConstantsElement = document.getElementById(elementId);
    if (!clientConstantsElement) {
        throw new Error("Missing YNAB_CLIENT_CONSTANTS!");
    }

    const jsonContent = window.decodeURIComponent(
        clientConstantsElement.textContent || clientConstantsElement.innerHTML,
    );

    return JSON.parse(jsonContent);
}

export function buildAmplitudeConfig(constants: ClientConstants) {
    return {
        apiKey: constants.AMPLITUDE_API_KEY,
        appVersion: constants.YNAB_APP_VERSION,
        userId: constants.USER?.id,
    };
}
