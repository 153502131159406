import { ready } from "./ready";

ready(() => {
    document.addEventListener("click", function (e) {
        if (e.target instanceof HTMLElement && e.target.hasAttribute("data-password-toggle")) {
            const passwordField = e.target.parentNode.previousSibling as HTMLInputElement;
            passwordField.setAttribute("type", passwordField.getAttribute("type") == "text" ? "password" : "text");
            passwordField.focus();
        }
    });
});
