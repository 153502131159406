import "@castleio/castle-js/dist/castle.browser.js";
import { Amplitude } from "ynab_shared_library/app/web/utilities/amplitude";
import { buildAmplitudeConfig } from "./clientConstants";
import { loadCookieConsent } from "./cookieConsent";
import { loadFacebookPixel } from "./facebook";
import { loadGoogleTagWithAnalytics } from "./googleTag";
import { loadRollbar } from "./rollbar";

export function setupLibraries(clientConstants: ClientConstants) {
    // Friendly little security message in production mode
    if (clientConstants.PRODUCTION) {
        console.log("%c%s", "color:red;font-size:4em;", "Stop!");
        console.log(
            "%c%s",
            "font-size:1.5em;",
            "The console is a browser feature intended for developers. If someone told you to copy-paste something here to enable a YNAB feature, it is a scam and will give them access to your YNAB account.",
        );
    }

    // We log this so that QA can see it more easily when testing
    console.log(`ynabAppVersion: ${clientConstants.YNAB_APP_VERSION}`);

    // Castle: Castle is a service that helps us defend against credential stuffing attacks,
    // It's client side js should be included before other tracking js including analytics
    // per their documentation
    if (clientConstants.CASTLE_PUBLISHABLE_KEY) {
        window.Castle.configure({ pk: clientConstants.CASTLE_PUBLISHABLE_KEY });
        if (clientConstants.CASTLE_USER_JWT) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            window.Castle.page({ userJwt: clientConstants.CASTLE_USER_JWT });
        }
    }

    if (clientConstants.ROLLBAR_CLIENT_TOKEN) {
        loadRollbar({
            accessToken: clientConstants.ROLLBAR_CLIENT_TOKEN,
            enabled: clientConstants.ROLLBAR_ENABLED,
            production: clientConstants.PRODUCTION,
            codeVersion: clientConstants.YNAB_APP_VERSION,
            environment: clientConstants.ROLLBAR_ENVIRONMENT,
            userId: clientConstants.USER?.id,
            email: clientConstants.USER?.email,
        });
    }

    const isImpersonating = clientConstants?.IMPERSONATING === true;

    if (clientConstants.COOKIE_PRO_SCRIPT_ID) {
        loadCookieConsent({
            showBanner: clientConstants.COOKIE_CONSENT_BANNER_SHOWN,
            isProduction: clientConstants.PRODUCTION,
            cookieProScriptId: clientConstants.COOKIE_PRO_SCRIPT_ID,
            onLoadedCallback() {
                if (!isImpersonating) {
                    Amplitude.init(buildAmplitudeConfig(clientConstants));
                }
            },
        });
    }

    if (clientConstants.GOOGLE_ANALYTICS_TAG_ID && !isImpersonating) {
        loadGoogleTagWithAnalytics({
            tagId: clientConstants.GOOGLE_ANALYTICS_TAG_ID,
            userId: clientConstants.USER?.id,
            sendPageView: clientConstants.GOOGLE_ANALYTICS_SEND_PAGE_VIEW,
        });
    }

    if (clientConstants.FACEBOOK_PIXEL_ID && !isImpersonating) {
        loadFacebookPixel({ facebookPixelId: clientConstants.FACEBOOK_PIXEL_ID });
    }
}
