/**
 * Tracks whether forms have changed fields, meaning they have become "dirty".
 *
 * When the dirty flag changes, the form submit is automatically disabled/enabled,
 * and it will trigger a callback if given.
 *
 * There one main ways of tracking:
 *
 * * "all fields present": the dirty flag is changed when all fields on the form
 * contain some value, when they are "all present". Whenever a single field
 * becomes blank again, the form is not "dirty" anymore.
 *
 * This class used to also track if:
 * * "any field changed": the dirty flag changed when a single field changed
 *                        from its original value.
 *
 * Example:
 *
 *    new FormDirtyTrackingAllFieldsPresent $('#my-form')
 *
 */
YNAB.FormDirtyTrackingAllFieldsPresent = class {
    form;
    changes;
    dirty;
    pickInputs;
    textInputs;
    fields;
    submit;
    callback;

    constructor(form, callback) {
        if (!form.length) {
            return;
        }

        this.form = form;
        this.callback = callback;

        this.changes = {};
        this.dirty = false;

        this.pickInputs = "input[type=radio], select";
        this.textInputs = "input[type=text], input[type=password], input[type=email], textarea";
        this.fields = this.form.find(`${this.textInputs}, ${this.pickInputs}`);
        this.submit = this.form.find("button[type=submit]");

        this.addEventListeners();
        this.initializeChanges();
    }

    addEventListeners() {
        // TODO: don't we need a form.off somewhere?
        this.form.on("change", this.pickInputs, this.elementChanged.bind(this));
        this.form.on("change keyup", this.textInputs, this.elementChanged.bind(this));
    }

    checkDirtyChanged(forceDirtyChanged = false) {
        const formChanged = this.formChanged();

        if (forceDirtyChanged || formChanged != this.dirty) {
            this.dirty = formChanged;
            this.dirtyChanged();
        }
    }

    dirtyChanged() {
        this.submit.prop("disabled", !this.dirty);
        if (this.callback) {
            this.callback(this);
        }
    }

    elementChanged(e) {
        this.trackInputChanges(e.currentTarget);
        this.checkDirtyChanged();
    }

    markElementChanged(element) {
        // This will force the element to be marked changed, regardless of if it actually is changed.
        this.setChanged(this.inputKey(element));
        this.checkDirtyChanged();
    }

    initializeChanges() {
        for (const field of this.fields) {
            this.trackInputChanges(field);
        }
        this.checkDirtyChanged(true);
    }

    inputKey(input) {
        return input.name || input.id;
    }

    fieldChanged(input) {
        return !!$.trim($(input).val().toString());
    }

    formChanged() {
        return this.numberOfFields() == this.numberOfChanges();
    }

    numberOfChanges() {
        return this.objectSize(this.changes);
    }

    numberOfFields() {
        const differentFields = {};
        for (const field of this.fields) {
            differentFields[this.inputKey(field)] = true;
        }

        return this.objectSize(differentFields);
    }

    objectSize(object) {
        return Object.keys(object).length;
    }

    setChanged(key) {
        this.changes[key] = "changed";
    }

    trackInputChanges(input) {
        const key = this.inputKey(input);

        if (this.fieldChanged(input)) {
            this.setChanged(key);
        } else {
            delete this.changes[key];
        }
    }
};
