import {
    hideFormErrors,
    handleCommonAuthErrors,
    addErrorToField,
    genericServerErrorMessage,
} from "ynab_api/lib/authentication_shared";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    declare resetFormTarget: HTMLFormElement;
    declare resetPasswordContainerTarget: HTMLElement;
    declare resetSubmitButtonTarget: HTMLElement;

    static targets: string[] = ["resetForm", "resetPasswordContainer", "resetSubmitButton"];

    public resetFormTargetConnected(form: HTMLFormElement): void {
        const resetForm = $(form) as JQuery<HTMLFormElement>;
        resetForm.validate();
    }

    public handleResetSuccess(_event: CustomEvent): void {
        // go to the Wellness Dashboard
        window.location.href = "/group";
    }

    public handleResetError(event: CustomEvent): void {
        const formTarget = $(this.resetFormTarget) as JQuery<HTMLFormElement>;
        hideFormErrors(formTarget);

        const [response, _status, xhr] = event.detail;

        const error: { id: string; message: string } = response?.error;
        if (xhr.status === 401 || xhr.status === 422) {
            if (error) {
                addErrorToField(formTarget, ".js-form-password", error.message);
            } else {
                addErrorToField(formTarget, ".js-form-password", genericServerErrorMessage);
            }
        } else {
            return handleCommonAuthErrors(formTarget, xhr);
        }
    }
}
