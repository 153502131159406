export enum AuthenticationProvider {
    Email = "email",
    Google = "google",
    Apple = "apple",
}

export interface SSOAuthParams {
    provider?: AuthenticationProvider;
    provider_token: string;
    provider_user_name?: string;
}

export interface EmailAuthParams {
    email: string;
    password: string;
    remember_me: boolean;
}

export interface SSORequestParameters {
    request_data: SSOAuthParams;
}

export interface ServerError {
    // ID for this type of error: "sql_error", "server_error", "unknown_operation_name"
    id: string;
    message: string;
    data: string;
}

export interface LoginUserServerResponse {
    error: ServerError;
    session_token: string;
    castle_user_jwt: string;
    user: {
        id: string;
        email: string;
        trial_expires_on: string;
        is_subscribed?: boolean;
        feature_flags: string[];
        sign_in_count?: number;
        annual_subscription_price?: number;
    };
}

export interface SignupServerResponse {
    id: string;
    email: string;
    session_token: string;
}
