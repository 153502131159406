import { Application } from "@hotwired/stimulus";

import DestroyOTPController from "./destroy_otp_controller";
import EditPasswordsController from "./edit_passwords_controller";
import LoginController from "./login_controller";
import NewPasswordsController from "./new_passwords_controller";
import OTPFormController from "./otp_form_controller";
import SetWellnessPasswordsController from "./set_wellness_passwords_controller";
import SignupController from "./signup_controller";
import UserSettingsController from "./user_settings_controller";

export function startStimulusApplication() {
    const application = Application.start();
    // Configure Stimulus development experience
    application.debug = false;
    // window.Stimulus   = application

    application.register("destroy-otp", DestroyOTPController);
    application.register("edit-passwords", EditPasswordsController);
    application.register("login", LoginController);
    application.register("new-passwords", NewPasswordsController);
    application.register("otp-form", OTPFormController);
    application.register("set-wellness-passwords", SetWellnessPasswordsController);
    application.register("signup", SignupController);
    application.register("user-settings", UserSettingsController);

    return application;
}
