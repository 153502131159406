import { ready } from "./ready";

ready(() => {
    document.querySelector<HTMLInputElement>(".js-canceled-apple-sub")?.addEventListener("change", function () {
        const button = document.querySelector<HTMLButtonElement>(".js-canceled-apple-sub-button");

        if (this.checked) {
            button.removeAttribute("disabled");
            button.classList.remove("disabled");
        } else {
            button.setAttribute("disabled", "disabled");
            button.classList.add("disabled");
        }
    });
});
