import jQuery from "jquery";
import { AuthenticationProvider, type SSOAuthParams } from "ynab_api/controllers/types";
import { initAppleIdentityButton, initGoogleIdentityButton } from "ynab_api/helpers/sso_button";

export function addErrorToField(form: JQuery<Element>, fieldClass: string, errorMessage: string) {
    form.find(fieldClass).find(".error").html(errorMessage).show().end().find("input").addClass("error");
}

export function hideFormErrors(form: JQuery<HTMLFormElement>) {
    form.find("input.error").removeClass("error").end().find(".error").hide();
}

export const genericServerErrorMessage = "There was an error with the server, please try again later.";

const AUTHENTICATION_SSO_BUTTON_ERROR_CLASS_NAME = "authentications-sso-button__error";
const APPLE_ERROR_CLASS_NAME = `${AUTHENTICATION_SSO_BUTTON_ERROR_CLASS_NAME}--apple`;
const GOOGLE_ERROR_CLASS_NAME = `${AUTHENTICATION_SSO_BUTTON_ERROR_CLASS_NAME}--google`;

export function hideAuthenticationSsoErrors() {
    jQuery(`.${APPLE_ERROR_CLASS_NAME}, .${GOOGLE_ERROR_CLASS_NAME}`).hide();
}

export function showAuthenticationSsoError(provider: AuthenticationProvider, message: string) {
    const errorClassName = provider === "apple" ? APPLE_ERROR_CLASS_NAME : GOOGLE_ERROR_CLASS_NAME;
    return jQuery(`.${errorClassName}`).html(message).fadeIn("fast");
}

export function initAppleIdentity(
    appleButton: HTMLFormElement,
    onSuccess: (data: SSOAuthParams, button: HTMLFormElement) => void,
) {
    jQuery(appleButton).on("apple:ready", (_event: any, auth: any) => {
        if (auth.error) {
            showAuthenticationSsoError(AuthenticationProvider.Apple, auth.errorMessage);
        } else {
            onSuccess(
                {
                    provider: AuthenticationProvider.Apple,
                    provider_user_name: auth.name,
                    provider_token: auth.token,
                },
                appleButton,
            );
        }
    });

    initAppleIdentityButton();
}

export function initGoogleIdentity(
    googleButton: HTMLFormElement,
    onSuccess: (data: SSOAuthParams, button: HTMLFormElement) => void,
) {
    jQuery(googleButton)
        .on("googleIdentified:success", (_, data: any) => {
            onSuccess({ provider: AuthenticationProvider.Google, provider_token: data.token }, googleButton);
        })
        .on("googleIdentified:error", (_, error) => {
            showAuthenticationSsoError(AuthenticationProvider.Google, error);
        });

    initGoogleIdentityButton();
}

function handleRetryAfter(form: JQuery<HTMLFormElement>, retryAfter: number = 10) {
    const lockedMinutes = Math.ceil(retryAfter / 60);
    const submit = form.find("button");
    let errorMessage = null;

    if (lockedMinutes > 60) {
        // Account is throttled in the User model, and won't be unlocked for a month.
        errorMessage = "Account locked. Please contact support.";
    } else {
        // Account is throttled at the rack-attack middleware, and will be unlocked soon.
        errorMessage = `Please try again in ${lockedMinutes} minutes.`;
    }

    submit.prop("disabled", true);
    return addErrorToField(form, ".js-form-password", errorMessage);
}

export function handleCommonAuthErrors(form: JQuery<HTMLFormElement>, xhr: any): void {
    switch (xhr.status) {
        case 403:
            window.location.reload();
            break;
        case 429:
            return handleRetryAfter(
                form,
                xhr.getResponseHeader("Retry-After") ? parseInt(xhr.getResponseHeader("Retry-After")) : null,
            );
        default:
            if (form.find(".js-form-email").length > 0) {
                return addErrorToField(form, ".js-form-email", genericServerErrorMessage);
            } else {
                return addErrorToField(form, ".js-form-password", genericServerErrorMessage);
            }
    }
}
