import * as $ from "jquery";
import RecurlySubscriptionForm from "./recurly_subscription_form";
import {
    SubscriptionFormDirtyTrackingAllFieldsPresent,
    SubscriptionFormDirtyTrackingAnyFieldsChanged,
} from "./subscription_form_dirty_tracking";
import { AnalyticsEvents } from "../analytics-events";

class SubscriptionForm extends RecurlySubscriptionForm {
    planTotal;
    billingAgreement;
    dirtyTracking;

    constructor(form) {
        super(form);
        if (!this.form) {
            return;
        }

        this.planTotal = this.form.find("[data-subscription-plan-total]");
        this.billingAgreement = this.form.find("[data-subscription-agreement]");

        this.initializePlanTotal();
        this.dirtyTracking = this.buildDirtyTracking();
    }

    // Tracks the dirty changes on the form to enable/disable submit differently
    // based on new and edit subscription.
    //   * new requires all fields present
    //   * edit requires plan type and/or billing to change
    buildDirtyTracking() {
        if (this.isEditingSubscription) {
            return new SubscriptionFormDirtyTrackingAnyFieldsChanged(this.form);
        } else {
            return new SubscriptionFormDirtyTrackingAllFieldsPresent(this.form);
        }
    }

    addEventListeners() {
        super.addEventListeners();
        this.form.on("change", "[data-plan]", (event) => {
            this.updatePlanTotal($(event.target));
        });
    }

    submitForm(event) {
        if (this.dirtyTracking.billingInfoDidNotChange()) {
            // updating plan type only, just let it submit the form.
            return true;
        } else {
            return this.submitFormHandler(event);
        }
    }

    initializePlanTotal() {
        const planElement = this.form.find("[data-plan]").filter(":checked");
        this.updatePlanTotal(planElement);
    }

    updatePlanTotal(selectedPlan) {
        const currentPlanCode = this.form.data("current-plan-code");
        const selectedPlanCode = selectedPlan.data("plan");
        const denom = selectedPlan.data("denom");
        const discountPrice = selectedPlan.data("discount-price");
        const fullPrice = selectedPlan.data("full-price");
        const priceToSwitch = selectedPlan.data("price-to-switch") || "$0";
        const netTotal = discountPrice || fullPrice;
        const nextBillingPrice =
            netTotal == selectedPlan.data("next-billing-price") ? "" : selectedPlan.data("next-billing-price");
        const billingCycleUnit = selectedPlan.data("billing-cycle-unit");
        const secondChargeDate = selectedPlan.data("second-charge-date");

        if (currentPlanCode == selectedPlanCode && !discountPrice) {
            // edit: nothing has changed
            this.updatePrices("", priceToSwitch, "", nextBillingPrice, billingCycleUnit, secondChargeDate);
        } else if (discountPrice) {
            // new/edit: there is a discount on this plan
            this.updatePrices(fullPrice, discountPrice, denom, nextBillingPrice, billingCycleUnit, secondChargeDate);
        } else if (currentPlanCode) {
            // edit: switching to annual without discount or monthly
            this.updatePrices("", priceToSwitch, denom, nextBillingPrice, billingCycleUnit, secondChargeDate);
        } else {
            // new: display normal plan price
            this.updatePrices("", fullPrice, denom, nextBillingPrice, billingCycleUnit, secondChargeDate);
        }
    }

    updatePage(newContent) {
        super.updatePage(newContent);
        // For new subscription page, change title to past tense
        const subscriptionHeader = $(".page-header h1");
        subscriptionHeader.text(subscriptionHeader.text().replace("Subscribe to YNAB", "Subscribed to YNAB"));
    }

    updatePrices(strikePrice, displayPrice, denomination, nextBillingPrice, billingCycleUnit, secondChargeDate) {
        this.resetSubscriptionTax();

        this.planTotal.find("[data-subscription-full-price]").text(strikePrice);
        this.planTotal.find("[data-subscription-price]").text(displayPrice);
        this.planTotal.find("[data-subscription-denom]").text(denomination);
        this.planTotal.find("[data-subscription-total]").text(displayPrice);

        this.billingAgreement
            .find("[data-subscription-summary-with-one-time-discount]")
            .toggleClass("hidden-pricing-summary", !nextBillingPrice);
        this.billingAgreement
            .find("[data-subscription-summary-without-one-time-discount]")
            .toggleClass("hidden-pricing-summary", !!nextBillingPrice);
        this.billingAgreement.find("[data-subscription-billing-cycle-unit]").text(billingCycleUnit);
        this.billingAgreement.find("[data-subscription-second-charge-date]").text(secondChargeDate);
    }
}

$(() => {
    new SubscriptionForm($(".subscription-form"));
});

YNAB.route("subscriptions#required", () => {
    // Track view event whenever the subscription required page is opened.
    YNABAnalytics.trackEvent(AnalyticsEvents.Subscription_ViewedExpirationMessage);
});

YNAB.route("subscriptions#new", "subscriptions#edit", () => {
    // Track view event whenever the subscription new/edit pages are opened.
    YNABAnalytics.trackEvent(AnalyticsEvents.PageView_ViewedSubscribe);
});
