YNAB.route("otps#create", "otps#new", function (body) {
    // Are there any errors? If so scroll down to the first
    const firstError = document.querySelector("label.error");
    if (firstError) {
        firstError.scrollIntoView();
    }

    // Copy Button Behavior
    let copying = null;
    const copyButtons = document.querySelectorAll<HTMLElement>(".copy-button");
    copyButtons.forEach((copyButton) => {
        copyButton.addEventListener("click", function (e) {
            e.preventDefault();
            if (copying) {
                return;
            }

            // If copy-button malformed, notify developer
            const input = this.querySelector<HTMLInputElement>("input");
            const span = this.querySelector<HTMLSpanElement>("span");
            if (!input || !span) {
                throw new Error("copy-button must have an input and span tag.");
            }

            // The text before and after copying
            const copiedText = span.dataset.copied;
            const copyText = span.textContent;

            // Select the text in the input and copy it
            input.select();
            YNAB.copyTextToClipboard(input);

            // Notify it's been copied and then after some time restore button
            this.classList.add("copied");

            span.textContent = copiedText;
            copying = setTimeout(
                function () {
                    this.classList.remove("copied");
                    span.textContent = copyText;
                    copying = null;
                }.bind(this),
                2000,
            );
        });
    });

    // Collapse Button Behavior
    const collapseButtons = document.querySelectorAll<HTMLDivElement>(".collapse-button");
    collapseButtons.forEach((collapseButton) => {
        const button = collapseButton.querySelector<HTMLButtonElement>("button");
        button?.addEventListener("click", (e) => {
            e.preventDefault();
            collapseButton.classList.toggle("expanded");
        });
    });
});
