import { AnalyticsEvents, AnalyticsProperties } from "../analytics-events";
import { ready } from "./ready";

ready(() => {
    const exitSurveyContainer = document.querySelector<HTMLElement>(".exit-survey-container");
    if (exitSurveyContainer === null) {
        return;
    }

    const submitButton = exitSurveyContainer.querySelector<HTMLButtonElement>(".send-feedback");
    const reasons = exitSurveyContainer.querySelectorAll<HTMLInputElement>("input[type=radio]");

    submitButton.addEventListener("click", () => {
        const selectedReasons = Array.from(reasons)
            .filter((r) => r.checked)
            .map((radio) => radio.value.toLowerCase());
        YNABAnalytics.trackEvent(AnalyticsEvents.Subscription_SelectedCancellationReason, {
            [AnalyticsProperties.ExitSurveyOption]: selectedReasons.join(", "),
        });
    });

    reasons.forEach((r) => {
        r.addEventListener("change", () => {
            const hasChecked = Array.from(reasons).some((cb) => cb.checked);
            if (hasChecked) {
                submitButton.removeAttribute("disabled");
            } else {
                submitButton.setAttribute("disabled", "disabled");
            }
        });
    });
});
