/**
 * Tracks whether forms have changed fields, meaning they have become "dirty".
 *
 * When the dirty flag changes, the form submit is automatically disabled/enabled,
 * and it will trigger a callback if given.
 *
 * There are two main ways of tracking:
 *
 * * "all fields present": the dirty flag is changed when all fields on the form
 * contain some value, when they are "all present". Whenever a single field
 * becomes blank again, the form is not "dirty" anymore.
 * * "any field changed": the dirty flag is changed when a single field changes
 * from its original value, which for a new form means the user start typing into
 * the first field, or change a radio button.
 *
 * This class is not meant to be used directly, there are two classes for each of
 * the variations above: 'SubscriptionFormDirtyTrackingAllFieldsPresent' and
 * 'SubscriptionFormDirtyTrackingAnyFieldsChanged'.
 *
 * Examples:
 *
 *   new SubscriptionFormDirtyTrackingAllFieldsPresent $('#my-form')
 *   new SubscriptionFormDirtyTrackingAnyFieldsChanged $('#my-form'), (dirty) ->
 *     console.log(dirty)
 *
 */
class SubscriptionFormDirtyTracking {
    form;
    changes;
    dirty;
    pickInputs;
    textInputs;
    railsManagedFields;
    recurlyManagedFields;
    submit;

    constructor(form) {
        if (!form.length) {
            return;
        }

        this.form = form;

        this.changes = {};
        this.dirty = false;

        this.pickInputs = "input[type=radio]";
        this.textInputs = "input[type=text]";
        // Recurly started including extra inputs for mobile devices that we need to ignore (those inputs are not visible by the user)
        const allInputs = this.form.find(`${this.textInputs},${this.pickInputs}`);
        const recurlyMobileInputsToIgnore = this.form.find(".recurly-hosted-field > input");
        this.railsManagedFields = allInputs.not(recurlyMobileInputsToIgnore);
        this.recurlyManagedFields = ["number", "month", "year", "cvv"];
        this.submit = this.form.find("button[type=submit]");

        this.addEventListeners();
        this.initializeChanges();
    }

    addEventListeners() {
        // listener for Rails-managed inputs
        this.form.on("change", this.pickInputs, this.railsManagedElementChanged.bind(this));
        this.form.on("change keyup", this.textInputs, this.railsManagedElementChanged.bind(this));

        // listener for Recurly-managed inputs
        recurly.on("change", this.recurlyManagedElementChanged.bind(this));
    }

    billingInfoDidNotChange() {
        return !(
            Object.prototype.hasOwnProperty.call(this.changes, "number") ||
            Object.prototype.hasOwnProperty.call(this.changes, "month") ||
            Object.prototype.hasOwnProperty.call(this.changes, "year") ||
            Object.prototype.hasOwnProperty.call(this.changes, "cvv") ||
            Object.prototype.hasOwnProperty.call(this.changes, "country") ||
            Object.prototype.hasOwnProperty.call(this.changes, "zip")
        );
    }

    checkDirtyChanged(forceDirtyChanged = false) {
        const formChanged = this.formChanged();
        const errorsArePresent = this.errorsArePresent();

        if ((forceDirtyChanged || formChanged != this.dirty) && !errorsArePresent) {
            this.dirty = formChanged || errorsArePresent;
            this.dirtyChanged();
        }
    }

    dirtyChanged() {
        this.submit.prop("disabled", !this.dirty);
    }

    errorsArePresent() {
        return $("label.error").is(":visible");
    }

    inputKey(input) {
        return input.name || input.id;
    }

    initializeChanges() {
        this.checkDirtyChanged(true);
    }

    railsManagedElementChanged(e) {
        this.trackRailsManagedInputChanges(e.currentTarget);
        this.checkDirtyChanged();
    }

    recurlyManagedElementChanged(state: any) {
        // unfortunately Recurly's types do not provide anything more specific than `any` for this arg
        // https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/recurly__recurly-js/lib/emitter.d.ts

        const activeElement = $(document.activeElement);

        const errorField = activeElement.parent();
        const errorLabel = errorField.parent().siblings("label.error");

        if (errorLabel.is(":visible")) {
            // Recurly.js resets the CSS classes on the `div.recurly-hosted-field` container
            // when its injected `iframe` gains `focus`.  This prematurely removes any
            // `error` class we may have applied to it.  We need to re-apply that class so
            // the UI correctly shows the field contains an error, but we have to wait a
            // tick or Recurly.js's reset won't have happened yet.
            setTimeout(() => {
                errorField.addClass("error");
            }, 1);
        }
        const fieldStates: [any, any][] = Object.entries(state.fields);
        for (const [field, fieldState] of fieldStates) {
            if (fieldState.empty) {
                delete this.changes[field];
            } else {
                // clear error display if the input has changed (length) and is not blank
                if (fieldState.focus && fieldState.length && fieldState.length != this.changes[field]) {
                    // Wait a tick for the current change completes, then remove the error
                    setTimeout(() => {
                        errorField.removeClass("error");
                    }, 1);
                    errorLabel.hide();
                }

                this.changes[field] = fieldState.length;
            }
        }

        this.checkDirtyChanged();
    }

    railsManagedFieldChanged(input) {
        return false;
    }

    trackRailsManagedInputChanges(input) {
        const key = this.inputKey(input);

        if (this.railsManagedFieldChanged(input)) {
            this.changes[key] = "changed";
        } else {
            delete this.changes[key];
        }
    }

    formChanged() {
        return false;
    }
}

export class SubscriptionFormDirtyTrackingAnyFieldsChanged extends SubscriptionFormDirtyTracking {
    formChanged() {
        return !$.isEmptyObject(this.changes);
    }

    railsManagedFieldChanged(input) {
        switch (input.type) {
            case "text":
                return input.value != input.defaultValue;
            case "radio":
                return input.checked != input.defaultChecked;
        }
    }
}

export class SubscriptionFormDirtyTrackingAllFieldsPresent extends SubscriptionFormDirtyTracking {
    initializeChanges() {
        this.railsManagedFields.each((_idx, input) => {
            this.trackRailsManagedInputChanges(input);
        });

        // We don't need to initialize changes for Recurly-managed fields since the
        // callback for Recurly-managed field changes fires on page load, which would
        // negate such initialization since the state of all Recurly-managed fields
        // is "unchanged".  This is inconsequential since we know at least one of the
        // Rails-managed fields (i.e. the subscription plan) is "changed" and that's
        // enough for the submit button to be disabled, which is the ultimate goal
        // of this block of code.

        super.initializeChanges();
    }

    formChanged() {
        // Taylor asked for this change, just to make sure we're not returning false negatives.
        return this.numberOfDifferentFields() <= this.numberOfChanges();
    }

    numberOfChanges() {
        return this.objectSize(this.changes);
    }

    numberOfDifferentFields() {
        const differentFields = {};

        for (const field of this.railsManagedFields) {
            differentFields[this.inputKey(field)] = true;
        }
        for (const field of this.recurlyManagedFields) {
            differentFields[field] = true;
        }
        return this.objectSize(differentFields);
    }

    objectSize(object) {
        return Object.keys(object).length;
    }

    railsManagedFieldChanged(input) {
        return !!$.trim($(input).val().toString());
    }
}
