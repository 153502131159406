import { ready } from "./ready";

ready(() => {
    document.querySelector(".js-contact-us")?.addEventListener("click", function () {
        window.KustomerChatWidget.openChat();
    });

    const radioInputs = document.querySelectorAll<HTMLInputElement>(".js-impersonation-allowed-option");
    const initialValue = Array.from(radioInputs).find((r) => r.checked)?.value;

    radioInputs.forEach((r) => {
        r.addEventListener("change", function () {
            const valueChanged = initialValue !== this.value;
            document.querySelector<HTMLButtonElement>(".js-button-save").disabled = !valueChanged;
        });
    });
});
