import * as $ from "jquery";
import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus";
import { AnalyticsEvents } from "ynab_api/analytics-events";
import {
    AuthenticationProvider,
    SSORequestParameters,
    LoginUserServerResponse,
    EmailAuthParams,
    SSOAuthParams,
} from "./types";
import {
    showAuthenticationSsoError,
    hideFormErrors,
    addErrorToField,
    genericServerErrorMessage,
    handleCommonAuthErrors,
    initAppleIdentity,
    initGoogleIdentity,
} from "ynab_api/lib/authentication_shared";
import OTPFormController from "./otp_form_controller";

export default class extends Controller {
    declare formTarget: HTMLFormElement;
    declare googleButtonTarget: HTMLFormElement;
    declare appleButtonTarget: HTMLFormElement;
    declare emailInputTarget: HTMLFormElement;
    declare passwordInputTarget: HTMLFormElement;
    declare rememberMeInputTarget: HTMLFormElement;
    declare identityContainerTarget: HTMLElement;
    declare backupCodeHeaderTarget: HTMLElement;
    declare loginHeaderTarget: HTMLElement;
    declare loginSubheaderTarget: HTMLElement;
    declare ssoButtonsTarget: HTMLElement;
    static targets: string[] = [
        "form",
        "emailInput",
        "passwordInput",
        "rememberMeInput",
        "identityContainer",
        "appleButton",
        "googleButton",
        "backupCodeHeader",
        "loginHeader",
        "loginSubheader",
        "ssoButtons",
    ];

    declare otpFormOutlet: OTPFormController;
    declare hasOtpFormOutlet: boolean;
    static outlets: string[] = ["otp-form"];

    private emailAuthParams: EmailAuthParams;
    private ssoAuthParams: SSOAuthParams;

    public appleButtonTargetConnected(appleButton: HTMLFormElement): void {
        initAppleIdentity(appleButton, this.onSSOSuccess);
    }

    public googleButtonTargetConnected(googleButton: HTMLFormElement): void {
        initGoogleIdentity(googleButton, this.onSSOSuccess);
    }

    public formTargetConnected(loginForm: HTMLFormElement): void {
        ($(loginForm) as JQuery<HTMLFormElement>).validate();
    }

    public otpFormOutletConnected(outlet: OTPFormController, _element: HTMLElement): void {
        outlet.onShowBackupHook = this.onShowOTPBackup.bind(this);
    }

    private onShowOTPBackup(): void {
        hideFormErrors($(this.formTarget) as JQuery<HTMLFormElement>);
        this.loginHeaderTarget.hidden = true;
        this.backupCodeHeaderTarget.hidden = false;
    }

    public beforeSend(event: CustomEvent) {
        hideFormErrors($(this.formTarget) as JQuery<HTMLFormElement>);
        if (this.ssoAuthParams && this.hasOtpFormOutlet && this.otpFormOutlet.visible) {
            event.detail[1].data = this.addSSOCredentialsToForm(event);
        } else {
            this.storeEmailCredentials();
        }
    }

    private storeEmailCredentials(): void {
        this.emailAuthParams = {
            email: this.emailInputTarget.value,
            password: this.passwordInputTarget.value,
            remember_me: this.rememberMeInputTarget.checked,
        };
    }

    private addSSOCredentialsToForm(request: CustomEvent): string {
        const requestData = decodeURI(request.detail[1].data);
        const requestParams = new URLSearchParams(requestData);

        requestParams.set("request_data[provider]", this.ssoAuthParams.provider);
        requestParams.set("request_data[provider_token]", this.ssoAuthParams.provider_token);

        return requestParams.toString();
    }

    public handleError(event: CustomEvent) {
        const formTarget = $(this.formTarget) as JQuery<HTMLFormElement>;
        hideFormErrors(formTarget);

        if (
            this.hasOtpFormOutlet &&
            this.otpFormOutlet.visible &&
            this.otpFormOutlet.handleOTPError(event, formTarget)
        ) {
            return;
        }

        const [response, _status, xhr] = event.detail;

        if (xhr.status === 401 || xhr.status === 422) {
            const error: { id: string; message: string } = response?.error;
            if (error) {
                switch (error.id) {
                    case "user_otp_required":
                        this.showOTP();
                        break;
                    case "user_not_found":
                        return addErrorToField(
                            formTarget,
                            ".js-form-email",
                            "We couldn't find an account with that email.",
                        );
                    case "user_password_invalid":
                        return addErrorToField(formTarget, ".js-form-password", "Incorrect password.");
                    default:
                        if (/^password_/.test(error.id)) {
                            return addErrorToField(formTarget, ".js-form-password", error.message);
                        } else {
                            return addErrorToField(formTarget, ".js-form-email", error.message);
                        }
                }
            }
        } else {
            return handleCommonAuthErrors(formTarget, xhr);
        }
    }

    public handleSuccess(event: CustomEvent) {
        const loginResponse = event.detail[0] as LoginUserServerResponse;

        if (this.emailAuthParams) {
            this.trackLogin(AuthenticationProvider.Email, loginResponse);
        } else if (this.ssoAuthParams) {
            this.trackLogin(this.ssoAuthParams.provider, loginResponse);
        }

        requestAnimationFrame(() => {
            Rails.disableElement(this.formTarget);
        });

        let redirectURL = null;

        if (this.otpFormOutlet.usingBackupCode) {
            redirectURL = this.formTarget.dataset.disabledOtpUrl;
        } else if (this.formTarget.dataset.redirectUrl) {
            redirectURL = this.formTarget.dataset.redirectUrl;
        }

        this.redirect(redirectURL);
    }

    private onSSOSuccess = (authParams: SSOAuthParams, button: HTMLFormElement): void => {
        $(button).prop("disabled", true);

        this.ssoAuthParams = authParams;

        const loginParams: SSORequestParameters = { request_data: authParams };

        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        $.ajax(this.formTarget.getAttribute("action"), {
            data: loginParams,
            dataType: "json",
            method: "POST",
        })
            .then((loginResponse, _textStatus, _jqXHR) => {
                this.trackLogin(authParams.provider, loginResponse);
                this.redirect();
            })
            .fail((jqXHR: JQueryXHR) => {
                $(button).prop("disabled", false);
                this.handleSSOAuthFailure(authParams.provider, jqXHR);
            });
    };

    private handleSSOAuthFailure(provider: AuthenticationProvider, jqXHR: JQueryXHR) {
        const error = jqXHR.responseJSON != null ? jqXHR.responseJSON.error : undefined;

        if ((jqXHR.status === 401 || jqXHR.status === 422) && error) {
            const providerName = provider.toLowerCase().replace(/(?:^|\s|-)\S/g, (w) => w.toUpperCase());
            switch (error.id) {
                case "user_otp_required":
                    this.showOTP();
                    break;
                case "user_not_found": {
                    const userNotFoundError = `
                        Your ${providerName} account is not linked to a YNAB account.
                        Please try a different login method if you have an account, or create a new account by <a href="/users/sign_up">signing up</a>.
                    `;
                    return showAuthenticationSsoError(provider, userNotFoundError);
                }
                default:
                    return showAuthenticationSsoError(provider, error.message);
            }
        } else {
            return showAuthenticationSsoError(provider, genericServerErrorMessage);
        }
    }

    public trackClickedSignUp() {
        YNABAnalytics?.trackEvent(AnalyticsEvents.SignUp_ClickedSignUp);
    }

    private trackLogin(provider: AuthenticationProvider, loginResponse: LoginUserServerResponse) {
        if (window.YNABAnalytics != null) {
            YNABAnalytics.trackLogin(provider, loginResponse.user.id);
        }
    }

    private redirect(url?: string | null) {
        const redirectURL = url ?? this.formTarget.dataset.redirectUrl ?? "";
        YNAB.redirectWithDelay(redirectURL);
    }

    private showOTP(): void {
        this.identityContainerTarget.hidden = true;
        this.loginSubheaderTarget.hidden = true;
        this.ssoButtonsTarget.hidden = true;
        this.otpFormOutlet.show();
    }
}
