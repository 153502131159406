import * as jQuery from "jquery";

(function ($) {
    $(() => {
        const invoiceForm = $(".invoice-form");
        const initialForm = invoiceForm.serialize();

        const submitButton = $("#invoice-address-submit");
        const removeButton = $("#invoice-address-clear");

        const inputFields = $<HTMLInputElement>(".invoice-form input[type=text]");
        const requiredFields = inputFields.not(".invoice-form input[name=address_two], input[name=company_name]");
        const nonZipCodeFields = inputFields.not(".invoice-form input[name=postal_code]");
        const formMethod = $(".invoice-form input[name=_method]");
        const addressCleared = $("input[name=address_state]").prop("value");

        const errorLabel = $(".invoice-form .error");

        const emptyFields = () => {
            return requiredFields.filter((_, element) => $.trim(element.value).length == 0);
        };
        const overMaxFieldLength = () => {
            return nonZipCodeFields.filter((_, element) => $.trim(element.value).length > 100);
        };
        const disableFields = () => {
            if (addressCleared == "true") {
                removeButton.prop("disabled", true);
            } else {
                removeButton.prop("disabled", false);
            }
        };

        const validateFields = () => {
            const fieldsAreNullOrEmpty = emptyFields().length > 0;
            const invalidFieldLength = overMaxFieldLength().length > 0;

            if (fieldsAreNullOrEmpty || invalidFieldLength) {
                submitButton.prop("disabled", true);
            }
            if (invalidFieldLength) {
                errorLabel.css("display", "inline-block");
            } else {
                errorLabel.css("di}splay", "none");
            }
        };

        disableFields();

        invoiceForm.on("input", (event) => {
            const currentForm = invoiceForm.serialize();

            if (initialForm != currentForm) {
                submitButton.prop("disabled", false);
            } else {
                submitButton.prop("disabled", true);
            }
            if (emptyFields().length == 0) {
                removeButton.prop("disabled", true);
            }
            disableFields();
            validateFields();
        });

        removeButton.on("click", (event) => {
            event.preventDefault();
            formMethod.val("delete");
            invoiceForm.trigger("submit");
            formMethod.val("put");
        });
    });
})(jQuery);
