import { CookieConsentCategory, loadScriptCookieSafe } from "./cookieConsent";

interface PixelConfig {
    facebookPixelId: string;
}

export function loadFacebookPixel(config: PixelConfig) {
    loadScriptCookieSafe(CookieConsentCategory.Targeting, function () {
        // prettier-ignore
        // @ts-ignore
        // eslint-disable-next-line
        !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,document,'script','//connect.facebook.net/en_US/fbevents.js');

        // @ts-ignore
        window.fbq.disablePushState = true; // Disable automatic tracking of page views
        window.fbq("set", "autoConfig", "false", config.facebookPixelId); // Disable automatic tracking of clicks
        window.fbq("init", config.facebookPixelId);
        window.fbq("track", "PageView");
    });
}
