import * as jQuery from "jquery";
import RecurlySubscriptionForm from "./recurly_subscription_form";

class GroupPaymentMethodForm extends RecurlySubscriptionForm {
    submitForm(event) {
        return this.submitFormHandler(event);
    }
}

(function ($) {
    $(() => {
        new GroupPaymentMethodForm($(".group-payment-method-form"));
    });
})(jQuery);
