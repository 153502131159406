import * as $ from "jquery";
import Rails from "@rails/ujs";
import { loadGoogleAds } from "./googleTag";

export default class RecurlySubscriptionForm {
    paymentErrorBlock;
    form;
    isEditingSubscription;

    constructor(form) {
        if (form.length === 0) {
            return;
        }
        this.form = form;

        this.paymentErrorBlock = this.form.find(".payment-error");
        this.configureRecurly();
        this.addEventListeners();
        this.isEditingSubscription = this.form.find("input[name=_method]").val() == "put";

        // Eagerly load Google Ads to track conversions
        loadGoogleAds();
    }

    addEventListeners() {
        // This callback is for the ajax form submission to Rails, not Recurly
        this.form.on("ajax:error", (event) => {
            const [data, _status, xhr] = event.detail;

            if (xhr.status >= 500) {
                this.showPaymentError(
                    "There was a problem with the server, please try again later or contact support.",
                );
            } else if (xhr.status == 422 && data && data.three_d_secure_token_id) {
                const threeDSecureTokenId = data.three_d_secure_token_id;
                this.process3DSecureToken(threeDSecureTokenId, event);
            } else {
                this.showPaymentError(xhr.responseText);
            }
        });

        // This callback is for the ajax form submission to Rails, not Recurly
        this.form.on("ajax:success", (event) => {
            const [data, _status, xhr] = event.detail;
            if (data && data.tax_amount != null) {
                // calculating tax
                this.previewSubscriptionTax(data);
            } else {
                // subscription form submission
                this.updatePage(xhr.responseText);
                // Track the conversion with Google for new subscriptions only.
                if (!this.isEditingSubscription) {
                    this.trackConversion();
                }
            }
        });

        this.form.on("input", "[data-recurly=full_name]", (event) => {
            const fullName = $(event.target);
            if (fullName.val().toString().length) {
                fullName.removeClass("error").siblings("label.error").hide();
            }
        });

        this.form.on("change", "[data-recurly=postal_code], [data-recurly=country]", (_event) => {
            this.resetSubscriptionTax();
        });

        this.form.on("submit", (event) => {
            event.preventDefault();
            this.hideErrors();
            return this.submitForm(event);
        });
    }

    configureRecurly() {
        const fullNameInputStyles = this.form.find("[data-recurly=full_name]").css(["fontFamily", "fontSize", "color"]);

        const fields = {
            all: {
                style: {
                    fontColor: fullNameInputStyles.color,
                    // We can only pass a single font-family here since it is hitting Google Fonts, otherwise the request fails.
                    fontFamily: fullNameInputStyles.fontFamily.toString().split(",")[0].trim(),
                    fontSize: fullNameInputStyles.fontSize,
                    padding: "0 0 0 6px",
                },
            },
            month: {
                style: {
                    placeholder: {
                        content: "mm",
                    },
                },
            },
            year: {
                style: {
                    placeholder: {
                        content: "yyyy",
                    },
                },
            },
        };

        // If recurly was blocked or failed to load,
        // try to report to Rollbar
        if (typeof recurly == "undefined") {
            this.setElementVisible(".js-no-recurly", true);
            this.setElementVisible(".js-yes-recurly", false);

            if (window.Rollbar) {
                window.Rollbar.error(new Error("Recurly was either blocked or failed to load"));
            }
        }

        const form = this.form;
        recurly.on("ready", () => {
            form.addClass("recurly-ready");
        });

        recurly.configure({
            cors: true,
            publicKey: $("meta[name=recurly-public-key]").attr("content"),
            required: ["cvv", "country", "zip"],
            fields: fields,
        });
    }

    displayErrorForField(field) {
        const findTarget = (identifier) => {
            if (identifier == "cvv") {
                return this.form.find("#cvv-error").show();
            } else {
                let errorElement = this.form.find(`[data-recurly=${identifier}]`);
                if (["month", "year"].indexOf(identifier) >= 0) {
                    errorElement = errorElement.parent(".expiry-fields");
                }
                return errorElement.siblings("label.error").show();
            }
        };
        if (["first_name", "last_name"].indexOf(field) >= 0) {
            findTarget("full_name").addClass("error");
        } else if (["month", "year"].indexOf(field) >= 0) {
            findTarget("month")
                .parent()
                .find("[data-recurly].expiry")
                .children(".recurly-hosted-field")
                .addClass("error");
        } else {
            findTarget(field).children(".recurly-hosted-field").addClass("error");
        }
    }

    formatPrice(price) {
        return `$${price.toFixed(2)}`;
    }

    hideErrors() {
        this.form.find(".payment-error").hide();
        this.form.find("label.error").hide();
        this.form.find(".recurly-hosted-field, input.error").removeClass("error");
    }

    showPaymentError(message) {
        this.paymentErrorBlock.find(".payment-error-message").text(message);
        this.paymentErrorBlock.fadeIn("fast");
    }

    previewSubscriptionTax(subscriptionTaxInfo) {
        const storeOriginalText = (element) => {
            element.data("subscription-tax-original-text", element.text());
            return element;
        };
        storeOriginalText(this.form.find("[data-subscription-tax-description")).text(
            subscriptionTaxInfo.tax_description,
        );
        storeOriginalText(this.form.find("[data-subscription-tax-amount]")).text(
            this.formatPrice(subscriptionTaxInfo.tax_amount),
        );
        storeOriginalText(this.form.find("[data-subscription-total]")).text(
            this.formatPrice(subscriptionTaxInfo.subscription_total),
        );

        this.form.find("[data-subscription-calculate-tax], [data-subscription-tax-addendum]").hide();
    }

    resetSubscriptionTax() {
        if (this.form.find("[data-subscription-calculate-tax]").is(":visible")) {
            // Nothing to reset as tax has not been calculated/previewed yet.
            return;
        }

        const restoreOriginalText = (element) => {
            element.text(element.data("subscription-tax-original-text"));
            return element;
        };
        restoreOriginalText(this.form.find("[data-subscription-tax-description"));
        restoreOriginalText(this.form.find("[data-subscription-tax-amount]"));
        restoreOriginalText(this.form.find("[data-subscription-total]"));

        this.form.find("[data-subscription-calculate-tax], [data-subscription-tax-addendum]").show();
    }

    process3DSecureToken(threeDSecureTokenId, submitEvent) {
        const setChallengeVisibility = (visible) => {
            // when we show the challenge, we hide the billing details form (and vice versa)
            this.setElementVisible(".payment-fields", !visible);
            this.setElementVisible(".subscription-purchase-total", !visible);
            this.setElementVisible(".three-d-secure-challenge", visible);
        };

        const setChallengeLoadingMessageVisibility = (visible) => {
            this.setElementVisible(".three-d-secure-challenge-loading-message", visible);
        };

        const risk = recurly.Risk();
        const threeDSecure = risk.ThreeDSecure({ actionTokenId: threeDSecureTokenId });

        threeDSecure.on("ready", (evt) => {
            // hide the challenge "loading" message
            setChallengeLoadingMessageVisibility(false);
        });

        threeDSecure.on("error", (err) => {
            // display an error message requesting the user retry or use a different payment method
            setChallengeVisibility(false);
            this.showPaymentError(err);
        });

        threeDSecure.on("token", (token) => {
            // send `token.id` to your server to retry your API request
            setChallengeVisibility(false);
            document.querySelector<HTMLInputElement>("input.three-d-secure-challenge-result-token").value = token.id;

            const formElement = this.form.get(0);
            Rails.disableElement(formElement);
            Rails.handleRemote.call(formElement, submitEvent);
        });

        setChallengeLoadingMessageVisibility(true);
        setChallengeVisibility(true);
        const threeDSecureChallengeContent = document.querySelector(".three-d-secure-challenge-3rd-party-content");
        threeDSecureChallengeContent.innerHTML = "";
        threeDSecure.attach(threeDSecureChallengeContent);
    }

    submitForm(event) {
        throw Error("RecurlySubscriptionForm.submitForm() should be implemented by concrete subclasses");
    }

    submitFormHandler(event) {
        const formElement = this.form.get(0);
        Rails.disableElement(formElement);

        const fullName = $.trim(this.form.find("[data-recurly=full_name]").val()).split(" ");
        this.form.find("[data-recurly=first_name]").val(fullName.slice(0, -1).join(" "));
        this.form.find("[data-recurly=last_name]").val(fullName.slice(-1).join(" "));

        recurly.token(this.form, (err, token) => {
            if (err) {
                if (err.fields) {
                    for (const field of err.fields) {
                        this.displayErrorForField(field);
                    }
                } else {
                    this.showPaymentError(
                        "There was an error with your request, please try again later or contact support.",
                    );
                    if (window.Rollbar) {
                        window.Rollbar.warning(`Subscription Error: ${err.message}`, err);
                    }
                }
                Rails.enableElement(formElement);
            }
            // recurly.js has filled in the 'token' field, all systems go, submit the form
            else {
                Rails.handleRemote.call(formElement, event);
            }
        });

        return false;
    }

    trackConversion() {
        const subscriptionAnalytics = $("#subscription_analytics");

        if (window.gtag) {
            // Google AdWords: Purchase/Sale Conversion Tracking
            window.gtag("event", "conversion", {
                send_to: "AW-1070740767/MlAzCN3TRRCf6sj-Aw",
                value: subscriptionAnalytics.data("net-amount"),
                currency: "USD",
                transaction_id: subscriptionAnalytics.data("id"),
                aw_remarketing_only: false,
            });

            // Hit GoogleAnalytics for Initial Subscription
            window.gtag("event", "purchase", {
                transaction_id: subscriptionAnalytics.data("id"),
                value: subscriptionAnalytics.data("net-amount"),
                currency: "USD",
                items: [
                    {
                        item_id: subscriptionAnalytics.data("sku"),
                        item_name: subscriptionAnalytics.data("google-analytics-name"),
                        item_category: "initial_payment",
                        price: subscriptionAnalytics.data("net-amount"),
                        quantity: subscriptionAnalytics.data("quantity"),
                    },
                ],
            });
        }

        if (window.fbq) {
            window.fbq("trackCustom", subscriptionAnalytics.data("google-analytics-name"), {
                value: subscriptionAnalytics.data("net-amount"),
                num_items: subscriptionAnalytics.data("quantity"),
                currency: "USD",
            });
        }
    }

    updatePage(newContent) {
        $(".page-main").hide().html(newContent).fadeIn();
    }

    setElementVisible(selector: string, visible: boolean) {
        const element = document.querySelector<HTMLElement>(selector);
        if (element) {
            element.style.display = visible ? "block" : "none";
        }
    }
}
