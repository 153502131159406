import { ready } from "./ready";

ready(() => {
    const button = document.querySelector<HTMLButtonElement>(".js-support-access-confirmation-form button");
    if (button && button.dataset.enable === "true") {
        YNABAnalytics.logoutOfChatWidgetProviders();
        YNABAnalytics.logoutAnalytics();
        button.removeAttribute("disabled");
    }
});
