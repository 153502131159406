import * as jQuery from "jquery";

/**
 * This module provides a way to initialize Apple and Google SSO buttons.
 * The CSS is defined in app/assets/stylesheets/_sso_button.scss
 * The HTML is defined in:
 * - UserSettingsHelper#apple_sso_button
 * - UserSettingsHelper#google_sso_button
 */

// State
const JS_DISABLED_CLASS_NAME = "js-disabled";

// Elements
const INNER_ELEMENT_CLASS_NAME = "sso-button__inner";
const LABEL_ELEMENT_CLASS_NAME = "sso-button__label";

// Variations
const APPLE_SSO_BUTTON_CLASS_NAME = "sso-button--apple";
const GOOGLE_SSO_BUTTON_CLASS_NAME = "sso-button--google";

export function findAppleSsoButton() {
    return jQuery(`.${APPLE_SSO_BUTTON_CLASS_NAME}`);
}

export function initAppleIdentityButton() {
    const $button = findAppleSsoButton();
    if ($button.length > 0) {
        $button.find(`.${LABEL_ELEMENT_CLASS_NAME}`).text($button.data("label"));
        enableSsoButton($button.get(0));

        if ($button.data("trigger-action")) {
            // Extract response data from Apple that's inside the button element
            const data = {
                email: $button.data("email"),
                error: $button.data("error"),
                name: $button.data("name"),
                state: $button.data("state"),
                token: $button.data("token"),
                errorMessage: null,
            };

            // Handle Apple's `user_cancelled_authorize` error, which means the user canceled
            // the request to login or sign up with Apple at the end of the auth flow
            data.errorMessage =
                data.error == "user_cancelled_authorize"
                    ? "You canceled your request to Sign In with Apple."
                    : data.error;

            $button.trigger("apple:ready", data);
        }
    }

    // Return button wrapped in a jQuery object
    return $button;
}

export function findGoogleSsoButton() {
    return jQuery(`.${GOOGLE_SSO_BUTTON_CLASS_NAME}`);
}

export function initGoogleIdentityButton() {
    // TODO: Move this error message to Rails
    const LOADING_ERROR_MESSAGE =
        "Unable to connect to Google. If you are using a popup blocker, temporarily disable it to try again.";

    const $button = findGoogleSsoButton();

    if (window.GoogleIdentity) {
        window.GoogleIdentity.onUserIdentified((token) => {
            $button.trigger("googleIdentified:success", { provider: "google", token });
        });

        window.GoogleIdentity.onIdentityServicesInitialized((loadError) => {
            const $buttonInner = $button.find(`.${GOOGLE_SSO_BUTTON_CLASS_NAME} .${INNER_ELEMENT_CLASS_NAME}`);
            if (loadError) {
                disableSsoButton($buttonInner.get(0));
                $button.trigger("googleIdentified:error", LOADING_ERROR_MESSAGE);
            } else {
                enableSsoButton($buttonInner.get(0));

                const fixedWidth = $button.data("width");
                const $buttonParent = $button.parent();
                window.GoogleIdentity.renderButton($button.get(0), {
                    width: fixedWidth || `${Math.round($buttonParent.outerWidth())}px`,
                });

                if (!fixedWidth) {
                    window.addEventListener("resize", () => {
                        const nextWidth = `${Math.round($buttonParent.outerWidth())}px`;

                        // The button element was removed by GoogleIdentity and it's no longer in the DOM.
                        // Find the button again and re-render it.
                        window.GoogleIdentity.renderButton(findGoogleSsoButton().get(0), {
                            width: nextWidth,
                        });
                    });
                }
            }
        });
    }

    // Return container wrapped in a jQuery object
    return $button;
}

export function enableSsoButton(button?: HTMLElement) {
    if (button instanceof HTMLButtonElement) {
        button.disabled = false;
    } else if (button instanceof HTMLElement) {
        button.classList.remove(JS_DISABLED_CLASS_NAME);
    }
}

export function disableSsoButton(button?: HTMLElement) {
    if (button instanceof HTMLButtonElement) {
        button.disabled = true;
    } else if (button instanceof HTMLElement) {
        button.classList.add(JS_DISABLED_CLASS_NAME);
    }
}

export function updateSsoButtonLabel(button: HTMLElement, label: string) {
    const $button = jQuery(button);
    $button.find(`.${LABEL_ELEMENT_CLASS_NAME}`).text(label);
}
