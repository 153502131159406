import { AnalyticsEvents, AnalyticsProperties } from "../analytics-events";

class ReferralProgramInviteForm {
    formClass = ".referral-program-invite-form";
    private body: JQuery;
    form;
    emailField;
    messageField;
    messageSuggestions;
    messageRandomSuggestions;
    private referralShareLinkField: JQuery;
    private referralShareLinkCopiedMessageTemplate: JQuery;
    sponsorNameField;

    constructor(body: JQuery) {
        this.body = body;

        this.initializeForm();
        this.initializeMessageSuggestion();
        this.addEventListeners();
        YNABAnalytics.trackEvent(AnalyticsEvents.PageView_ViewedReferralProgram);
    }

    addEventListeners() {
        this.body.on("ajax:error", this.formClass, (event) => {
            const xhr = event.detail[2];
            switch (xhr.status) {
                case 422:
                    this.showInviteError(xhr.responseText);
                    break;
                case 429:
                    this.showInviteError("Oops, too many attempts. Please wait a moment before trying again.");
                    break;
                default:
                    this.showInviteError("Please try again later or contact support.");
                    break;
            }
        });

        this.body.on("ajax:success", this.formClass, (event) => {
            const xhr = event.detail[2];
            this.showInviteSuccess(xhr.responseText);
        });

        this.body.on("click", "[data-message-toggle]", (event) => {
            this.toggleMessageSuggestions($(event.target));
        });

        this.body.on("click", "#referral-share-link", (event) => {
            if (event.target instanceof HTMLInputElement) {
                event.target.select();
            }
        });

        this.body.on("click", "#copy-button", (event) => {
            this.selectLinkAndCopyToClipboard();
        });

        this.body.on("click", ".social-share-link", (event) => {
            this.trackSocialShare($(event.currentTarget));
        });

        this.sponsorNameField.on("input", () => this.updateReferralUrls());
    }

    initializeForm() {
        this.form = this.body.find(this.formClass);
        new YNAB.FormDirtyTrackingAllFieldsPresent(this.form);

        this.emailField = this.form.find("input[type=email]");
        this.messageField = this.form.find("[data-messages]");
        this.sponsorNameField = this.form.find("#referral_sponsor_name");

        this.referralShareLinkField = this.body.find("#referral-share-link");

        const copiedMsg = this.body.find(".referral-share-content-link .copied-msg");
        this.referralShareLinkCopiedMessageTemplate = copiedMsg.clone().prop("hidden", false);
        copiedMsg.remove(); // remove original template
    }

    initializeMessageSuggestion() {
        this.messageSuggestions = this.messageField.data("messages");
        this.messageRandomSuggestions = null;

        this.toggleMessageSuggestions(this.body.find("[data-message-toggle]").first());
    }

    // Create a copy of the messages in random order so we can suggest one by one
    // randomly, and get back the original list when we run out of messages to display.
    randomMessage() {
        if ($.isEmptyObject(this.messageRandomSuggestions)) {
            this.messageRandomSuggestions = this.messageSuggestions.slice().sort(() => 0.5 - Math.random());
        }
        return this.messageRandomSuggestions.shift();
    }

    toggleMessageSuggestions(toggleButton) {
        const messageIndex = parseInt(toggleButton.data("message-toggle"), 10);
        const message = isNaN(messageIndex) ? this.randomMessage() : this.messageSuggestions[messageIndex - 1];

        this.messageField.val(message).trigger("change");
        toggleButton.siblings("[data-message-toggle]").removeClass("selected").end().addClass("selected");
    }

    selectLinkAndCopyToClipboard() {
        const container = this.body.find("#copy-link-container");

        const tmpTextarea = document.createElement("textarea");
        tmpTextarea.value = this.referralShareLinkField.val().toString();
        tmpTextarea.setAttribute("aria-hidden", "true");
        tmpTextarea.style.position = "absolute";
        tmpTextarea.style.left = "-9999px";
        container.append(tmpTextarea);

        tmpTextarea.select();
        YNAB.copyTextToClipboard(tmpTextarea);
        YNABAnalytics.trackEvent(AnalyticsEvents.Social_SentReferralInvite, {
            [AnalyticsProperties.ReferralMethod]: "Link",
        });

        jQuery(tmpTextarea).remove();

        const status = this.referralShareLinkCopiedMessageTemplate.clone();
        status.attr("role", "alert");
        status.attr("aria-live", "polite");
        container.find(".referral-share-content-link").append(status);
        setTimeout(() => status.remove(), 2000);
    }

    showInviteError(errorMessage) {
        this.body.find(".referral-invite-success").hide();
        this.body.find(".referral-invite-error").hide().find("span").text(errorMessage).end().fadeIn("fast");
        this.emailField.focus();
    }

    showInviteSuccess(updatedReferralsHtml) {
        // Wait a bit before triggering the change event so it plays nice with dirty
        // tracking and rails js, otherwise the submit button will be re-enabled.
        setTimeout(() => {
            this.emailField.val("").trigger("change").focus();
        }, 25);
        this.body.find(".referral-invite-error").hide().find("span").empty();
        this.body.find(".referral-invite-success").hide().fadeIn("fast");
        this.body.find(".referral-section").replaceWith(updatedReferralsHtml);
        YNABAnalytics.trackEvent(AnalyticsEvents.Social_SentReferralInvite, {
            [AnalyticsProperties.ReferralMethod]: "Email",
        });
    }

    trackSocialShare(link) {
        YNABAnalytics.trackEvent(AnalyticsEvents.Social_SentReferralInvite, {
            [AnalyticsProperties.ReferralMethod]: link.data("platform"),
        });
    }

    updateReferralUrls() {
        const referralUrl = new URL(this.referralShareLinkField.val().toString());
        const sponsorName = this.sponsorNameField.val().trim();

        if (sponsorName) {
            referralUrl.searchParams.set("sponsor_name", sponsorName);
        } else {
            referralUrl.searchParams.delete("sponsor_name");
        }

        this.referralShareLinkField.val(referralUrl.toString());
    }
}

YNAB.route("referral_program#index", (body) => {
    new ReferralProgramInviteForm(body);
});
